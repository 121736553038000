@keyframes pricing-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pricing {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  animation-name: pricing-animation;
  animation-duration: 300ms;

  .pricing-item {
    background-color: #eeeeee;
    position: relative;
    padding: 16px;
    border: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    outline: none;
    .pricing-table {
      border-spacing: 0;
      font-size: 0.9rem;
      color: #8d8d8d;
      width: 100%;
      .pricing-unit {
        font-size: 1.25rem;
        font-weight: bold;
        text-transform: uppercase;
        color: #293c4b;
        .pricing-unit-point {
          font-size: 1.5rem;
          line-height: 1.25rem;
          color: #ff4536;
        }
      }
      .pricing-head-cell,
      .pricing-cell {
        text-align: right;
        vertical-align: bottom;
      }
    }
  }
}
