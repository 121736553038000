/* ======  ALL COLORS =========*/

/* =========================== */

/* ======= FONTS ============= */

/* ======  RESPOSIVE BREAKPOINTS  =========*/

$for-mobile-only: "(max-width: 767px)";
$for-desktop-only: "(min-width: 768px)";

// Usage :
//    @media #{$for-phone-only} {
//      background: red;
//    }

/* ======  ELEMENTS  =========*/
@import "./semantic.scss";
@import "./common.scss";
@import "./container/Container.scss";
@import "./footer/Footer.scss";
@import "./header/Header.scss";
@import "./home/Home.scss";
@import "./cart/components/CartModal.scss";
@import "./about/About.scss";
@import "./pricing/Pricing.scss";
@import "./marketplace/Marketplace.scss";

.ens-header-logo {
  width: 225px;
  height: 22.125px;
}
