.terms-conditions {
  text-decoration: none;
  outline: none;
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: color 100ms 0ms ease-in-out;
  color: #888;
}

.terms-conditions:hover {
  text-decoration: underline;
  color: #888;
}

.terms-conditions:focus {
  text-decoration: underline;
  color: #888;
}

.terms-conditions:active {
  text-decoration: underline;
  color: #888;
}

.footer {
  flex-shrink: 0;
  width: 92%;
  max-width: 1500px;
  font-size: 0.8rem;
  text-align: center;
  margin: 28px auto 32px auto;
}
