.cart-header {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.mobile-label {
  display: none;
  @media screen and (max-width: 767px) {
    display: inline !important;
  }
}

.mobile-cart-button {
  @media screen and (max-width: 767px) {
    margin-top: 10px !important;
  }
}
