$layout-breakpoint-small: 767px;

.youtube-container {
  position: fixed;
  z-index: 100;
  width: 92%;
  max-width: 1500px;
  max-height: 80vh;
  margin: auto;
  opacity: 0.4;
  &.video-on {
    opacity: 1;
  }
  @media (max-width: $layout-breakpoint-small) {
    position: relative;
    opacity: 1;
  }
}
.video-search-container {
  position: relative;
  width: 100%;
  max-width: 1500px;
}
.watch-video {
  position: fixed;
  z-index: 1010;
  bottom: 21%;
  right: 10%;
}
.home-search {
  position: fixed;
  width: 100%;
  max-width: 1500px;
  //background: transparent;
  margin: 40vh auto auto auto;
  transition: all 300ms;
  z-index: 1000;
  &.active {
    position: relative;
    margin-top: 0vh;
  }
  &.video-on {
    z-index: -1;
  }
  @media (max-width: $layout-breakpoint-small) {
    position: relative;
    margin: 20px 0;
  }
}

.search-result {
  margin: auto auto auto auto;
  width: 100%;
  max-width: 904px;
  padding: 0;
  padding-top: 0px;
  list-style: none;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(285px, 290px));
  animation-name: _f44a36aa;
  animation-duration: 300ms;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  //min-height: 800px;
  @media (max-width: $layout-breakpoint-small) {
    grid-template-columns: repeat(auto-fit, minmax(285px, 0.95fr));
    margin-left: 5px;
    margin-right: 5px;
  }

  .eos-account {
    background-color: #fafafa;
    position: relative;
    padding: 16px;
    border: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    outline: none;
    min-height: 96px;
    transition: transform 100ms 0ms ease, box-shadow 100ms 0ms ease;
    cursor: pointer;
    .hasDiscount {
      color: #ff4536 !important;
      font-size: 12px;
      vertical-align: middle;
      margin-left: 6px;
    }
    .account-name {
      margin: 0 0 4px 0;
      font-size: 1.25rem;
      font-weight: bold;
      flex-grow: 1;
    }
    .account-info {
      font-size: 0.95rem;
      .info {
        display: flex;
        align-items: center;
        i.icon {
          height: auto;
        }
        .eos-logo {
          height: 24px;
          width: 24px;
          margin-top: 0;
          margin-left: -2px;
        }
      }
      &.available {
        color: #666666;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
      }
    }
    &::after {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 17.5px;
      top: 50%;
      transform: translateY(-50%);
      content: " ";
      background-size: cover;
      animation-name: _f44a36aa;
      animation-duration: 300ms;
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(4 4)' fill-rule='nonzero'%3E%3Cpath d='m29.791 12.455c1.6867 10.27-7.0667 19.023-17.335 17.335-6.2147-1.02-11.225-6.0321-12.247-12.246-1.6867-10.27 7.0667-19.023 17.336-17.335 6.2133 1.02 11.224 6.0321 12.245 12.246z' fill='%23BFBFBF'/%3E%3Cpath d='m6.5401 16.214c2.1195-1.8828 5.4624-3.0664 8.5913-3.0664 3.1303 0 6.4549 1.1836 8.5927 3.0664l-1.166 1.5521c-2.3712-1.2995-4.5102-1.9596-7.4266-1.9596-2.9151 0-5.0528 0.66016-7.3862 1.9596l-1.2052-1.5521z' fill='%23fff'/%3E%3Cpath d='m18.364 10.508c-0.85565 0-1.5743-0.71745-1.5743-1.5716 0-0.85417 0.7187-1.5729 1.5743-1.5729 0.85565 0 1.5757 0.71875 1.5757 1.5729 0 0.85417-0.72 1.5716-1.5757 1.5716z' fill='%23fff'/%3E%3Cpath d='m11.774 10.508c-0.85565 0-1.5743-0.71745-1.5743-1.5716 0-0.85417 0.7187-1.5729 1.5743-1.5729 0.85435 0 1.5743 0.71875 1.5743 1.5729 0 0.85417-0.72 1.5716-1.5743 1.5716z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
    &.available {
      background-color: #eeeeee;
      &.auction-link {
        text-decoration: none;
        color: #444;
      }
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='50%25' x2='50%25' y2='100%25'%3E%3Cstop stop-color='%23B8E986' offset='0'/%3E%3Cstop stop-color='%23A5DC6D' offset='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg%3E%3Cpath d='m33.791 16.455c1.6867 10.27-7.0667 19.023-17.335 17.335-6.2147-1.02-11.225-6.0321-12.247-12.246-1.6867-10.27 7.0667-19.023 17.336-17.335 6.2133 1.02 11.224 6.0321 12.245 12.246z' fill='url(%23a)'/%3E%3Cpath d='m27.724 18.7c-2.1195 1.8828-5.4624 3.0664-8.5913 3.0664-3.1303 0-6.4549-1.1836-8.5927-3.0664l1.166-1.5521c2.3712 1.2995 4.5102 1.9596 7.4266 1.9596 2.9151 0 5.0528-0.66016 7.3862-1.9596l1.2052 1.5521z' fill='%23fff'/%3E%3Cpath d='m22.364 14.508c-0.85565 0-1.5743-0.71745-1.5743-1.5716 0-0.85417 0.7187-1.5729 1.5743-1.5729 0.85565 0 1.5757 0.71875 1.5757 1.5729 0 0.85417-0.72 1.5716-1.5757 1.5716z' fill='%23fff'/%3E%3Cpath d='m15.774 14.508c-0.85565 0-1.5743-0.71745-1.5743-1.5716 0-0.85417 0.7187-1.5729 1.5743-1.5729 0.85435 0 1.5743 0.71875 1.5743 1.5729 0 0.85417-0.72 1.5716-1.5743 1.5716z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
      &.noprice {
        &::after {
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(4 4)' fill-rule='nonzero'%3E%3Cpath d='m29.791 12.455c1.6867 10.27-7.0667 19.023-17.335 17.335-6.2147-1.02-11.225-6.0321-12.247-12.246-1.6867-10.27 7.0667-19.023 17.336-17.335 6.2133 1.02 11.224 6.0321 12.245 12.246z' fill='%23BFBFBF'/%3E%3Cpath d='m6.5401 16.214c2.1195-1.8828 5.4624-3.0664 8.5913-3.0664 3.1303 0 6.4549 1.1836 8.5927 3.0664l-1.166 1.5521c-2.3712-1.2995-4.5102-1.9596-7.4266-1.9596-2.9151 0-5.0528 0.66016-7.3862 1.9596l-1.2052-1.5521z' fill='%23fff'/%3E%3Cpath d='m18.364 10.508c-0.85565 0-1.5743-0.71745-1.5743-1.5716 0-0.85417 0.7187-1.5729 1.5743-1.5729 0.85565 0 1.5757 0.71875 1.5757 1.5729 0 0.85417-0.72 1.5716-1.5757 1.5716z' fill='%23fff'/%3E%3Cpath d='m11.774 10.508c-0.85565 0-1.5743-0.71745-1.5743-1.5716 0-0.85417 0.7187-1.5729 1.5743-1.5729 0.85435 0 1.5743 0.71875 1.5743 1.5729 0 0.85417-0.72 1.5716-1.5743 1.5716z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
          //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='%23A5DC6D' d='M2.3,20.28L11.9,10.68L10.5,9.26L9.78,9.97C9.39,10.36 8.76,10.36 8.37,9.97L7.66,9.26C7.27,8.87 7.27,8.24 7.66,7.85L13.32,2.19C13.71,1.8 14.34,1.8 14.73,2.19L15.44,2.9C15.83,3.29 15.83,3.92 15.44,4.31L14.73,5L16.15,6.43C16.54,6.04 17.17,6.04 17.56,6.43C17.95,6.82 17.95,7.46 17.56,7.85L18.97,9.26L19.68,8.55C20.07,8.16 20.71,8.16 21.1,8.55L21.8,9.26C22.19,9.65 22.19,10.29 21.8,10.68L16.15,16.33C15.76,16.72 15.12,16.72 14.73,16.33L14.03,15.63C13.63,15.24 13.63,14.6 14.03,14.21L14.73,13.5L13.32,12.09L3.71,21.7C3.32,22.09 2.69,22.09 2.3,21.7C1.91,21.31 1.91,20.67 2.3,20.28M20,19A2,2 0 0,1 22,21V22H12V21A2,2 0 0,1 14,19H20Z' /%3E%3C/svg%3E");
        }
      }
      &.auction {
        &::after {
          //background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(4 4)' fill-rule='nonzero'%3E%3Cpath d='m29.791 12.455c1.6867 10.27-7.0667 19.023-17.335 17.335-6.2147-1.02-11.225-6.0321-12.247-12.246-1.6867-10.27 7.0667-19.023 17.336-17.335 6.2133 1.02 11.224 6.0321 12.245 12.246z' fill='%23BFBFBF'/%3E%3Cpath d='m6.5401 16.214c2.1195-1.8828 5.4624-3.0664 8.5913-3.0664 3.1303 0 6.4549 1.1836 8.5927 3.0664l-1.166 1.5521c-2.3712-1.2995-4.5102-1.9596-7.4266-1.9596-2.9151 0-5.0528 0.66016-7.3862 1.9596l-1.2052-1.5521z' fill='%23fff'/%3E%3Cpath d='m18.364 10.508c-0.85565 0-1.5743-0.71745-1.5743-1.5716 0-0.85417 0.7187-1.5729 1.5743-1.5729 0.85565 0 1.5757 0.71875 1.5757 1.5729 0 0.85417-0.72 1.5716-1.5757 1.5716z' fill='%23fff'/%3E%3Cpath d='m11.774 10.508c-0.85565 0-1.5743-0.71745-1.5743-1.5716 0-0.85417 0.7187-1.5729 1.5743-1.5729 0.85435 0 1.5743 0.71875 1.5743 1.5729 0 0.85417-0.72 1.5716-1.5743 1.5716z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='%23A5DC6D' d='M2.3,20.28L11.9,10.68L10.5,9.26L9.78,9.97C9.39,10.36 8.76,10.36 8.37,9.97L7.66,9.26C7.27,8.87 7.27,8.24 7.66,7.85L13.32,2.19C13.71,1.8 14.34,1.8 14.73,2.19L15.44,2.9C15.83,3.29 15.83,3.92 15.44,4.31L14.73,5L16.15,6.43C16.54,6.04 17.17,6.04 17.56,6.43C17.95,6.82 17.95,7.46 17.56,7.85L18.97,9.26L19.68,8.55C20.07,8.16 20.71,8.16 21.1,8.55L21.8,9.26C22.19,9.65 22.19,10.29 21.8,10.68L16.15,16.33C15.76,16.72 15.12,16.72 14.73,16.33L14.03,15.63C13.63,15.24 13.63,14.6 14.03,14.21L14.73,13.5L13.32,12.09L3.71,21.7C3.32,22.09 2.69,22.09 2.3,21.7C1.91,21.31 1.91,20.67 2.3,20.28M20,19A2,2 0 0,1 22,21V22H12V21A2,2 0 0,1 14,19H20Z' /%3E%3C/svg%3E");
        }
      }
    }

    &:hover {
      transform: scale(1.02);
    }
  }
}

.purchase-options-container {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  width: 100%;
}

.purchase-option {
  flex: 1;
}
