.page-contents {
  .about-us-youtube-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    max-width: 800px;
    margin: 0 auto;
  }
}
