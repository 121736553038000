.header-wrapper {
  background-color: white;
  height: 80px;
  width: 100%;
  border-bottom-color: #e8e8e8;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.app-header {
  padding-top: 20px;
  margin: auto;
  background-color: white;
}

.sign-in,
.lang-select {
  background: none !important;
  z-index: 10000;
}

.login-button-container {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  margin-top: 8px;
  max-height: 150px;
}

.mobile-eos-message {
  display: none;
  @media screen and (max-width: 400px) {
    display: flex;
  }
}

.login-button {
  cursor: pointer;
  border: 0;
  background-color: #ff4536;
  color: #fff;
  outline: none;
  transition: color 100ms 0ms ease-in-out,
    background-color 100ms 0ms ease-in-out;
  border: 1px solid currentColor;
  background-color: transparent;
  color: #ff4536;
  font-size: 14px;
  border-radius: 4px;
  padding: 0.7rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-button:not(:disabled):hover {
  color: #fff;
  background-color: hsla(4, 100%, 61%, 0.897);
}
.scatter-logo {
  width: 100px;
  margin-top: 8px;
}

.eos-logo {
  width: 100px;
  margin-top: 8px;
}

.referral-modal-content {
  display: flex;
  flex-direction: column;
  input {
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    padding: 0.8rem;
    margin: 0.5rem 0;
    font-size: 1rem;
    min-width: 10px;
  }
  .copy-btn {
    button,
    button:hover,
    button:focus {
      background-color: #ff4536;
      color: #fff;
    }
    button:active {
      background-color: #ff6d62;
      color: #fff;
    }
  }
}
