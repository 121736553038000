.marketplace {
  margin: auto;
}

.marketplace-header {
  font-size: 2.7rem;
  padding: 20px 0 20px 0;
  border-bottom: 2px solid #ff4536;
}

.mktplc-header-left {
  background-color: #ff4536;
  padding: 24px !important;
  .white-text {
    color: white;
    a {
      color: white !important;
      text-decoration: none;
    }
  }
}

.mktplc-header-right {
  padding: 24px !important;
}

.marketplace-desc {
  width: 90%;
  margin: auto;
  padding: 24px !important;
  font-size: 1.5rem !important;
}

.marketplace-nav-icons {
  text-align: center;
  margin-top: 40px;
}

.marketplace-buy-sell {
  color: black;
}

.search-box {
  min-width: 450px;
  padding: 24px !important;
  width: 90%;
  margin: auto;
  @media screen and (max-width: 767px) {
    min-width: 0;
    padding: 0px !important;
    width: 100%;
  }
}

.marketplace-table-header {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.search-box-text {
  font-size: 1.5rem;
  width: 400px;
}

.search-button-outer {
  float: right;
  height: 80px;
  width: 80px;
  border-width: 4px;
  border-style: solid;
  border-color: #ff4536;
  border-radius: 50%;
  background-color: #ff4536;
  padding: 1px 6px;
  justify-content: center;
  margin-left: 4px;
}

.search-button {
  margin: auto;

  height: 45px;
  width: 45px;
  fill: white;
}

.search-input {
  min-width: 400px;
  height: 22.85px;
  margin-top: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(224, 224, 224);
  flex-grow: 1;
  //width: calc(100%-49px);
}

.search-inner-container {
  //height: 100px;
  display: flex;
  flex-direction: row;
}

.buy-trigger:hover {
  cursor: pointer;
}

.youtube-modal-trigger:hover {
  cursor: pointer;
}

.owner-account-warning {
  color: #ff4536;
}

.marketplace-modal-login {
  margin: auto;
}
