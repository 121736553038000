.desktop-main {
  //overflow: scroll;
  padding-bottom: 50px;
}

.desktop-header {
  padding-top: 8px;
  margin-bottom: 16px;
  //height: 10vh;
  //min-height: 100px;
  padding-left: 0px !important;
  .item {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
}

.header-menu {
  a {
    color: #858585;
    text-decoration: none;
    font-weight: 600;
    outline: none;
    transition: color 100ms 0ms ease;
    padding: 0;
    height: 50px;
    margin: 0;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: 1rem;
  }

  .referral-menu-item {
    color: #ff4536 !important;
  }

  a:hover {
    color: black;
  }

  .item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.mobile-sidebar {
  a {
    color: #858585;
    text-decoration: none;
    font-weight: 600;
    outline: none;
    transition: color 100ms 0ms ease;
    padding: 0;
    height: 50px;
    margin: 0;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: 1rem;
  }

  a:hover {
    color: black;
  }
}

.desktop-sub-header {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: white !important;
  background-color: #ff4536;
  height: 40px;
  padding-top: 10px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  a {
    color: white;
    text-decoration: none;
  }
}

.sign-in {
  z-index: 20;
  color: #858585 !important;
  text-decoration: none;
  font-weight: 600 !important;
  outline: none !important;
  transition: color 100ms 0ms ease !important;
  padding: 0;
  height: 50px;
  margin: 0;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 1rem !important;

  a:hover {
    color: black;
  }
}

.sitewide-message {
  margin: 0px !important;
  background-color: #ff4536;
  text-align: center;
}

.twitter-logo {
  color: #858585;
  fill: #858585;

  :hover {
    fill: #00aced;
  }
}

.telegram-logo {
  color: #858585;
  fill: #858585;

  :hover {
    fill: #0088cc;
  }
}

.ui.menu .lang-select {
  color: #858585 !important;
  font-weight: 600 !important;
  .menu > .item.lang-option {
    font-size: 0.8rem !important;
    color: #858585 !important;
    font-weight: 600 !important;
  }
}

.children-wrapper {
  min-height: calc(100vh - 200px);
  //overflow: scroll;
  margin-bottom: 40px;
}

.ask-questions-open {
  color: white;
  height: 40px;
  text-align: center;
  background-color: #ff4536 !important;
  padding-top: 9px;
  cursor: pointer;
}

.questions-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
}

.questions-column {
  height: 400px;
  width: 400px;
  margin: auto;
  flex: 1;
  .headline {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 25.5px;
    max-width: 400px;
    font-size: 1.2rem;
    font-weight: 700;
    color: rgb(41, 60, 75);
  }
  .info {
    margin: auto;
    max-width: 400px;
  }
}

.questions-middle-column {
  border-left: 2px solid #ff4536;
  border-right: 2px solid #ff4536;
}

.mobile-menu-item {
  padding-left: 10px !important;
}
